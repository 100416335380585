import PropTypes from 'prop-types'
import React from 'react'
import logo from '../images/logo/logo-web-1.png'
import amazonLogo from '../images/amazon/available_at_amazon_en_vertical.png'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className='image'>
      <img src={logo} alt='' />
    </div>
    <div className="content">
      <div className="inner">
        <h1>tile niche supporter</h1>
        <p>Perfect for holding tiles up when lacking bottom support</p>
        <div className='amazon-logo'><a href='https://www.amazon.com/Supporter-Perfect-holding-lacking-support/dp/B08WR3C4TN/ref=mp_s_a_1_3?dchild=1&keywords=planum+tile+supporter&qid=1634069257&sr=8-3' target='_blank'><img src={amazonLogo} alt=''/></a></div>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            Intro
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            How To
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            About
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('gallery')
            }}
          >
            Gallery
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
